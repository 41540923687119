/**
 * Created by numbsquirell on 6/9/21
 */

import React from "react";
import './Game.css'
import GameApiAdapter from "./GameApiAdapter";
import {massageSender} from "../MassageSender";

class Game extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            game:   props.game,
        }

        this.iframe = React.createRef();
    }

    componentDidMount() {
        this.init();
    }

    componentWillReceiveProps(props) {
        if (props.game) {
            this.setState({game: props.game})
        }
    }

    init() {
        const iframe                         = this.iframe.current;
        this.gameApiAdapter                  = new GameApiAdapter(iframe)
        this.gameApiAdapter.onMessageHandler = massageSender;
    }

    render() {
        return (
            <div id="game_wrapper">
                <iframe ref={this.iframe} src={this.state.game} frameBorder="0" title="" volume = {0}/>
            </div>
        )
    }
}

export {Game}