/**
 * Created by numbsquirell on 7/6/21
 */

import {typeSelector} from "./TypeSelector";
import {imageElemCreator} from './imageElemCreator';
import {bottomElemCreator} from "./bottomElemCreator";
import {massageSender} from "../../MassageSender";

import './MenuElement.scss'

function MenuElement(props) {
    const element      = props.element;
    const type         = typeSelector(element);
    const localization = props.localization;
    const imageElem    = imageElemCreator(element, type);
    const bottomElem   = bottomElemCreator(element, type, localization);
    return (
        <div className={"menu_element"} onClick={() => {
            massageSender({message: `app.button.${element.item}`})
        }}>
            {imageElem}
            <div className={"bottom_element_wrapper"}>
                {bottomElem}
            </div>
        </div>
    )
}

export {MenuElement};