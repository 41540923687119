/**
 * Created by numbsquirell on 6/10/21
 */

import './TournamentInterface.css'
import {PlayersPanel} from "./PlayersPanel/PlayersPanel";
import {InfoPanel} from "./InfoPanel/InfoPanel";

function TournamentInterface(props) {
    const leaderboard       = props.leaderboard;
    const tournamentRoom    = props.tournamentRoom;
    const tournament        = props.tournament
    const makeFirstSpinText = props.makeFirstSpinText;
    const uid               = props.uid

    return <div id="tournament">
        {leaderboard.find(u => u.id === uid) ?
            <div id="tournament_interface_wrapper">
                <InfoPanel tournamentRoom={tournamentRoom} tournament={tournament}/>
                <PlayersPanel localization={props.localization} leaderboard={leaderboard} uid={uid}/>
            </div>
            :
            <div className={"first_spin"}>
                {makeFirstSpinText}
            </div>
        }

    </div>
}


export {TournamentInterface}