/**
 * Created by numbsquirell on 6/16/21
 */


function massageSender(message) {
    if (!message.message) {
        return
    }
    window.location.href = 'uniwebview://' + message.message;
}

export {massageSender}