/**
 * Created by numbsquirell on 7/9/21
 */
import './../MenuElement.scss'

function DefaultLocalizedLabel(props){
    const label  = props.label;

    return <div className={"element_name_label"}>
        {label}
    </div>
}

export {DefaultLocalizedLabel}