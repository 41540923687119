/**
 * Created by numbsquirell on 6/14/21
 */

import './PlayerCard.css'

function PlayerCard(props) {
    const name = props.name;
    const avatarSrc = props.avatarUrl;
    const score = props.score;
    const yourClassName = props.your ?"your_card" : "common_card";


    return (<div className={`player_card ${yourClassName}`}>
        <div className={"avatar_wrapper"}>
            <img className="avatar" src={avatarSrc} alt={"logo"}/>
        </div>
        <div className={"info_block"}>
            <p className={"name_label"}>{name}</p>
            <p className={"score_label"}>{score}</p>
        </div>
    </div>)
}

export {PlayerCard}