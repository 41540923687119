/**
 * Created by numbsquirell on 7/7/21
 */

function OfferImage(props) {
    const skinName = props.element && props.element.values && props.element.values.params.skin;
    const imageUrl = `https://static.evocsn.com/public/components/holidayOfferPopup/resources/images/${skinName}_left.png`
    return (<div className={"default_image"} style={{
        backgroundImage: `url(${imageUrl})`
    }}>

    </div>)
}

export {OfferImage};