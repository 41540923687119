/**
 * Created by numbsquirell on 7/9/21
 */
import {Timer} from "../../TournamentInterface/InfoPanel/Timer/Timer";
import {Bar} from "./BottomElements/Bar";
import {DefaultLocalizedLabel} from "./BottomElements/DefaultLocalizedLabel";
import {localization} from "../../localization";

function bottomElemCreator(elem, type, localizationLang) {
    switch (type) {
        case 'bar':
            return <Bar element={elem}/>;
        case 'offer':
            const timeLeft = elem.values ? elem.values.expiresAt - Math.round(Date.now() / 1000) : 0;
            return <Timer timeLeft={timeLeft} lifeTime={0}/>
        default:
            const labelText = localization[elem.item][localizationLang]
            return <DefaultLocalizedLabel label={labelText} localization={localization}/>
    }
}

export {bottomElemCreator}