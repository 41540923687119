/**
 * Created by numbsquirell on 6/7/21
 */

const STATE_EVENT = {
    data: {
        message: 'app.state',
        state: {
            balance: null,
            pause: null,
            prompts: null,
            sound: {
                enabled: null,
                visible: true,
            },
            fullscreen: {
                enabled: null,
                visible: true
            }
        }
    }
};

export default class GameApiAdapter {

    constructor(iframe) {
        this._iframe = iframe;
        this._onMessageHandler = null;

        this._messageListener = (event) => this.onMessage(event);
    }

    sendMessage(message, data = {}) {
        if (!this._iframe || !this._iframe.contentWindow) {
            return;
        }
    }

    onMessage(event) {
        if (!event || !event.data || typeof event.data !== 'object') {
            return;
        }

        let updateState = false;
        let data = event.data;
        if ('type' in data) {
            if (data.type === 'game.loaded') {
                updateState = true;
            }
            data = this.transformLegacyEventData(data);
            if (!data) {
                return;
            }
        }

        if (this._onMessageHandler) {
            this._onMessageHandler(data);
            if (updateState) {
                this._onMessageHandler(STATE_EVENT.data);
            }
        }
    }

    transformLegacyEventData(data) {
        const createEventData = (message, data = {}) => ({message, ...data});

        if (data.type === 'game.loaded') {
            return createEventData('app.loaded', {designSize: null});
        }
        else if (data.type === 'game.exit') {
            return createEventData('app.lobby');
        }
        else if (data.type === 'game.monetization') {
            return createEventData('app.bank');
        }
        else if (data.type === 'spin.start') {
            return createEventData('app.step.start', {name: 'spin'});
        }
        else if (data.type === 'spin.stop') {
            return createEventData('app.step.end', {name: 'spin'});
        }
        else if (data.type === 'gamble.start') {
            return createEventData('app.step.start', {name: 'gamble'});
        }
        else if (data.type === 'gamble.stop') {
            return createEventData('app.step.end', {name: 'gamble'});
        }

        return null;
    }

    set onMessageHandler(handler) {
        this._onMessageHandler = handler;
        if (handler) {
            window.addEventListener('message', this._messageListener);
        }
        else {
            window.removeEventListener('message', this._messageListener);
        }
    }
}
