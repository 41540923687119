/**
 * Created by numbsquirell on 7/2/21
 */
import './GameInterface.scss'
import {menuSelector} from "./MenuSelector";
import {MenuElement} from "./MenuElement/MenuElement";

function GameInterface(props) {
    const menuSettings = props.menuSettings;
    const menuState    = props.menuState;
    const menuList     = menuSelector(menuSettings, menuState);

    return (
        <div id="game_interface">
            {menuList.map((e, id) => {
                return <MenuElement className={"interface_element"} key={id} element={e} localization={props.localization}/>
            })
            }
        </div>
    )
}


export {GameInterface};