/**
 * Created by numbsquirell on 7/6/21
 */
import './../MenuElement.scss'
import {calculatePercent} from './calculatePercent'
import React, {useRef} from 'react'

function FillImage(props) {
    const values                          = props.element.values || {}
    const [percent, percentStr, slashStr] = calculatePercent(values);
    const itemName                        = props.element.item;
    const emptyImageRef                   = useRef(null);
    const fullImageRef                    = useRef(null);
    let imageEmpty                        = require(`../../../../assets/${itemName}_empty.png`);
    let imageFull                         = require(`../../../../assets/${itemName}_full.png`);
    const value                           = emptyImageRef.current ? fullImageRef.current.offsetHeight - fullImageRef.current.offsetHeight * (percent / 100 ) : 100;
    return (<div ref={emptyImageRef} className={'fill_image_empty'} style={
        {
            backgroundImage: `url("${imageEmpty.default}"`
        }
    }>
        <div ref={fullImageRef} className="fill_image_full" style={
            {
                backgroundImage: `url("${imageFull.default}"`,
                clip:            'rect(' + value + 'px, auto, auto, auto)'
            }
        }>

        </div>
        <p className={"percent_label"}>
            {percentStr || ""}
        </p>
    </div>)
}

export {FillImage}