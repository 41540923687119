/**
 * Created by numbsquirell on 7/6/21
 */

function typeSelector(element) {
    let type = "default";
    switch (element.item) {
        case "pig":
            type = "fill";
            break;
        case "userXP":
            type = "fill";
            break;
        case "dailyQuests":
            type = "bar";
            break;
        case "offer":
            type = "offer";
            break;

    }
    return type;
}

export {typeSelector}