/**
 * Created by numbsquirell on 6/16/21
 */
import React from "react";
import clockImage from "../../../../assets/clock.png";
import "./Timer.css"

class Timer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            started: !!props.timeLeft,
            currentTime: props.timeLeft ? props.timeLeft : props.lifeTime
        }

        this.timer = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.timeLeft !== this.props.timeLeft || prevProps.lifeTime !== this.props.lifeTime) {
            this.setState({
                started: this.props.timeLeft,
                currentTime: this.props.timeLeft ? this.props.timeLeft : this.props.lifeTime
            })
        }
        if (this.timer) {
            clearInterval(this.timer);
        }
        if (this.state.started) {
            this.startTimer();
        }
    }

    startTimer() {
        this.timer = setInterval(() => {
            this.decrementCurrentTime()
        }, 1000)
    }

    decrementCurrentTime() {
        const currentTime = this.state.currentTime;
        this.setState({currentTime: currentTime - 1})
    }

    toHHMMSS(time) {
        if (time) {
            let sec_num = parseInt(time, 10); // don't forget the second param
            let hours = Math.floor(sec_num / 3600);
            let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            let seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours < 10) {
                hours = "0" + hours;
            }
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            if (seconds < 10) {
                seconds = "0" + seconds;
            }
            return hours + ':' + minutes + ':' + seconds;
        }
    }

    render() {
        return (<div className={"timer"}>
            <p className={"timer_label"}>
                <img className={"clock_image"} src={clockImage}/>
                {this.toHHMMSS(this.state.currentTime)}
            </p>
        </div>)
    }
}

export {Timer}