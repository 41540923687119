/**
 * Created by numbsquirell on 6/14/21
 */
import './InfoPanel.css'
import chestImage from '../../../assets/coinChest.png'
import playerImage from '../../../assets/player.png'
import coinImage from '../../../assets/coin.png'
import exitImage from '../../../assets/upperButton.png'
import {Timer} from "./Timer/Timer";
import {massageSender} from "../../MassageSender";

function InfoPanel(props) {
    const tournamentRoom = props.tournamentRoom;
    const players = tournamentRoom ? tournamentRoom.people : 0;
    const coins = tournamentRoom ? tournamentRoom.prizeFund : 0;
    const closesAt = tournamentRoom ? tournamentRoom.closesAt : null;
    const timeLeft = (tournamentRoom && closesAt) ? tournamentRoom.closesAt - Math.round(Date.now() / 1000) : null
    const tournament = props.tournament ? props.tournament : null;
    const lifeTime = tournament ? tournament.lifetime : null;
    function exitGame(){
        massageSender({message: "app.lobby"})
    }

    return (<div id={"info_panel"}>
        <div id={"chest"}>
            <p className={"players_count"}>
                <img className={"player_image"} src={playerImage}/>
                {players}
            </p>
            <p className={"money_fund"}>
                <img className={"coin_image"} src={coinImage}/>
                {coins}
            </p>
            <img className={"exit_button"} src={exitImage} onClick={()=>{exitGame()}}/>
            <img className={"big_image"} src={chestImage}/>
        </div>
        <Timer timeLeft={timeLeft} lifeTime={lifeTime}/>
    </div>)
}

export {InfoPanel}