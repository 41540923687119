import {calculatePercent} from "./calculatePercent";

/**
 * Created by numbsquirell on 7/7/21
 */

function DefaultImage(props) {
    const itemName = props.element.item;
    let image      = require(`../../../../assets/${itemName}.png`)
    return (<div className={"default_image"} style={{
        backgroundImage: `url("${image.default}"`
    }}>


    </div>)
}

export {DefaultImage};