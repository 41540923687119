/**
 * Created by numbsquirell on 7/6/21
 */

function menuSelector(menuSettings, menuState) {
    const list   = [];
    menuSettings = menuSettings.sort((e1, e2) => e1.params.place - e2.params.place)

    menuSettings.forEach(e => {
        if (menuState[e.item]) {
            e.values = menuState[e.item]
        }
        if (list[e.params.place]) {
            if (list[e.params.place].params.priority < e.params.priority && menuState[e.item] !== null) {
                list[e.params.place] = e;
            }
        } else if (menuState[e.item] !== null) {
            list[e.params.place] = e;
        }
    });

    return list
}


export {menuSelector}