/**
 * Created by numbsquirell on 6/11/21
 */

import './PlayersPanel.css'
import {PlayerCard} from "./PlayerCard/PlayerCard";
import {localization} from "../../localization";

function PlayersPanel(props) {
    const leaderboard = props.leaderboard;
    const uid = props.uid

    const players = leaderboard.map((player, index) => {
        const your = player.id === uid
        return (<PlayerCard
            your = {your}
            name = {your ? localization.you[props.localization] : player.name}
            score = {player.score}
            avatarUrl = {player.avatarUrl}
            key={index}/>)
    });

    return (
        <div className="players_panel">
            {players}
        </div>
    )
}

export {PlayersPanel}