/**
 * Created by numbsquirell on 7/6/21
 */

import {FillImage} from "./ImageElements/FillImage";
import {OfferImage} from "./ImageElements/OfferImage";
import {DefaultImage} from "./ImageElements/DefaultImage";

function imageElemCreator(elem, type) {
    switch (type) {
        case "fill":
            return <FillImage element={elem}/>
        case "offer":
            return <OfferImage element={elem}/>
        default:
            return <DefaultImage element={elem}/>
    }
}

export  {imageElemCreator}