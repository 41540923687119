/**
 * Created by numbsquirell on 6/16/21
 */

const localization = {
    make_first_spin: {
        ru: "Сделайте первый спин",
        en: "Make first spin"
    },
    userXP:          {
        ru: "уровень",
        en: "level"
    },
    pig:             {
        ru: "копилка",
        en: "money pig"
    },
    dailyQuests:     {
        ru: "квесты",
        en: "quests"
    },
    coins: {
        ru: "банк",
        en: "banks"
    },
    vip: {
        ru: "vip",
        en: "vip"
    },
    bonusRoom: {
        ru: "бонус",
        en: "bonus"
    },
    you:{
        ru: "Ты",
        en: "You"
    }
}

export {localization}