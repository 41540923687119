/**
 * Created by numbsquirell on 7/7/21
 */

function calculatePercent(valuesObj) {
    const maxVal     = valuesObj.maxVal;
    const curVal     = valuesObj.curVal;
    const slashStr   = clamp(curVal, maxVal) + '/' + maxVal;
    const percent    = Math.round(clamp((curVal / maxVal) * 100));
    const percentStr = percent + "%";

    return [percent, percentStr, slashStr]
}

function clamp(val1, val2) {
    return val1 > val2 ? val2 : val1;
}

export {calculatePercent};