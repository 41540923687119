/**
 * Created by numbsquirell on 6/7/21
 */

import HTTPRequestController from "./HTTPRequestController";
import Centrifuge from 'centrifuge';
import SockJS from 'sockjs-client'

const UNPROCESSABLE_ERROR_CODE   = 422;
const SESSION_EXPIRED_ERROR_CODE = 403;
const BAD_REQUEST = 400;


const LOG_STYLE = `background: #ffacd1; color: #07118f; font-weight: bold`;

export class ServerManager{
    constructor(baseUrl = '', token = null, sessionId = null) {
        this._baseUrl   = baseUrl;
        this._token     = token;
        this._sessionId = sessionId;
        this._platform  = null;

        this._onErrorHandler = null;
        this._onConnectionLostHandler = null;
        this._onReconnectHandler = null;

        this._centrifuge = null;
        this._onMessageHandler = null;
        this._onSocketDisconnectHandler = null;
        this._subscribedChannels = [];

        this._pause = false;
        this._reconnecting = false;

        Object.seal(this);
    }

    async state(of, params = {}) {
        return await this.request('state', {of, params});
    }

    async request(url, params = {}, reconnect = true, method = 'POST') {
        if (params.of && params.params) {
            console.log(`%cServerManager: state of: '${params.of.first}' params:`, LOG_STYLE, params.params);
        }
        else {
            console.log(`%cServerManager: request: '${url}' payload:`, LOG_STYLE, params);
        }

        if (this._pause && !this._reconnecting) {
            return {};
        }

        const baseUrl = url.substring(0, 4) ===  'http' ? '' : this._baseUrl;
        const connection = new HTTPRequestController(baseUrl, 'JSON', method);
        connection.onErrorHandler = (error) => this.onError(url, params, error, reconnect);
        connection.onReconnectHandler = this._onReconnectHandler;
        connection.onConnectionLostHandler = this._onConnectionLostHandler;

        params = {token: this._token, ...params};
        const response = await connection.send(url, params);
        if (response && !response.error) {
            this._onMessageHandler(response);
            this._reconnecting = false;
            this._pause = false;
        }
        else if (response) {
            if (response.error.code === UNPROCESSABLE_ERROR_CODE || response.error.code === BAD_REQUEST) {
                return {error: response.error};
            }
        }

        return (response) || {};
    }

    async socketConnect(url, platform, user, token, timestamp) {
        console.log(`%cServerManager: centrifuge: connect...`, LOG_STYLE);
        this._platform = platform;

        this._centrifuge = new Centrifuge(url, {
            user,
            timestamp,
            sockjs: SockJS
        });

        this._centrifuge.setToken(token);

        let r1 = await this.socketSubscribe('public');
        let r2 = await this.socketSubscribe(`user#${user}`);

        return new Promise((resolve) => {
            this._centrifuge.on('connect', (e) => {
                console.log(`%cServerManager: centrifuge: connected! ${JSON.stringify(e)}`, LOG_STYLE);
                resolve(e);
            });
            this._centrifuge.on('disconnect', (e) => {
                if (this._onSocketDisconnectHandler) {
                    this._onSocketDisconnectHandler(e);
                }
            });
            this._centrifuge.connect();
        });
    }

    async socketSubscribe(channel) {
        if (this._subscribedChannels.includes(channel)) {
            return;
        }
        this._subscribedChannels.push(channel);

        this._centrifuge.subscribe(`${this._platform}:v3${channel}`, ({data}) => { // Added prefix 'v3' before channel name for new V3 System of inner IDs
            if (this._pause && !this._reconnecting) {
                return;
            }
            if (!('state' in data && 'log' in data.state && Object.keys(data.state).length === 1)) {
                console.log(`%cServerManager: WS:`, LOG_STYLE, data);
            }
            this._onMessageHandler(data);
        });
    }

    onError(url, params, error, reconnect = true) {
        if (error.code === UNPROCESSABLE_ERROR_CODE) {
            error.message = `${UNPROCESSABLE_ERROR_CODE} Request problem: ${url}`;
            reconnect = false;
            this._pause = true;
        }
        else if (error.code === SESSION_EXPIRED_ERROR_CODE) {
            error.message = `${SESSION_EXPIRED_ERROR_CODE} Session expired! Please reload game!`;
            this._pause = true;
            this._reconnecting = true;
        } else if (error.code === BAD_REQUEST) {
            error.message = `BAD_REQUEST ${BAD_REQUEST}`;
            reconnect = false;
            this._reconnecting = false;

            return reconnect;
        }

        if (this._onErrorHandler) {
            this._onErrorHandler(error);
        }

        return reconnect;
    }

    set onMessageHandler(handler) {
        this._onMessageHandler = handler;
    }

    set onErrorHandler(handler) {
        this._onErrorHandler = handler;
    }

    set onConnectionLostHandler(handler) {
        this._onConnectionLostHandler = handler;
    }

    set onReconnectHandler(handler) {
        this._onReconnectHandler = handler;
    }

    set onSocketDisconnectHandler(handler) {
        this._onSocketDisconnectHandler = handler;
    }

    get baseUrl() {
        return this._baseUrl;
    }

    get token() {
        return this._token;
    }

    set token(token) {
        this._token = token;
    }
}