import './App.css';
import React from 'react';
import {ServerManager} from "../network/ServerManager";
import Mock from "../network/mock";
import {Game} from "./Game/Game";
import {TournamentInterface} from "./TournamentInterface/TournamentInterface";
import {localization} from "./localization";
import {massageSender} from "./MassageSender";
import {GameInterface} from "./GameInterface/GameInterface";

class App extends React.Component {

    constructor(props) {
        super(props);
        const params = window.location.search.slice(1).replace('?', '').split('&').reduce(this.reduceParams, {})

        this.state = {
            wToken:         params.wsToken || null,
            platformId:     params.uid && params.uid.replace('----', ' ') || null,
            wsUrl:          params.wsUrl || null,
            ts:             params.ts || null,
            uToken:         params.uToken || null,
            baseUrl:        params.server ? this.createBaseUrl(params.server) : null,
            tournamentId:   params.tId || null,
            gameAlias:      params.gameAlias || "evoplay\\fruitburst",
            localization:   params.localization === 'Russian' ? 'ru' : 'en',
            tournamentRoom: null,
            platform:       'evoslots',
            staticUrl:      "https://static.evocsn.com/public/",
            appState:       null,
            gameState:      null,
            game:           null,
            gameToken:      null,
            tournament:     null,
            v3Id:           null,

            pig:         null,
            offer:       null,
            dailyQuests: null,
            userXP:      null,
            vip:         false,

            leaderboard: []
        }
    }

    reduceParams(data, param) {
        param          = param.split('=');
        data[param[0]] = param[1];
        return data;
    }

    createBaseUrl(server) {
        return `https://${server}/evoslots/mobile/api/v3/`
    }

    componentDidMount() {
        this.init();
    }

    async init() {
        // TODO remove
        // ////////////////////////////////////////////////////////////////////////////////////////////////////////
        // this.mock = new Mock();
        // await this.mock.init();
        // this.mockToState();
        // ////////////////////////////////////////////////////////////////////////////////////////////////////////
        this.createServerManager();
        await this.getGameState();
        await this.connectToSocket();

        if (this.state.tournamentId) {
            await this.initTournamentGameType();
        } else {
            await this.initNormanGameType();
        }
    }


    //TODO remove
    mockToState() {
        this.setState({
            wToken:       this.mock.ws.token,
            platformId:   this.mock.uid,
            wsUrl:        this.mock.ws.ws,
            ts:           this.mock.ws.ts,
            uToken:       this.mock.token,
            baseUrl:      this.mock.baseURl,
            tournamentId: this.mock.tournamentId,
            localization: this.mock.localization
        })
    }

    async initTournamentGameType() {
        await this.startTournament();
        await this.setGameLink(this.state.tournamentRoom.game);
    }

    async initNormanGameType() {
        await this.setGameLink(this.state.gameAlias);
    }

    createServerManager() {
        const state                  = this.state;
        this.server                  = new ServerManager(state.baseUrl, state.uToken);
        this.server.onMessageHandler = (massage) => {
            this.onMassageHandler(massage)
        };
    }

    async getGameState() {
        const gameState = await this.server.state(this.state.uToken);

        this.setState({gameState: gameState.data});
        this.onMassageHandler({state: gameState.data});
    }

    async connectToSocket() {
        const state      = this.state;
        const user       = state.gameState.user;
        const autResp    = await this.server.request('authenticate', {uid: state.platformId});
        const signinResp = await this.server.request('signin', autResp);

        const resp = await this.server.socketConnect(state.wsUrl, state.platform, user.id, signinResp.data.ws.token, state.ts);
    };

    async startTournament() {
        const response = await this.server.request('tournament/join', {tournamentId: this.state.tournamentId});
        //TODO add ctatch low balance
        this.addNewRoom(response);
        const tournament = this.state.gameState.tournaments.tournaments.find(tournament => tournament.id === Number(this.state.tournamentId))
        const roomObj    = this.state.gameState.tournaments.rooms.find(room => room.id === response.data.roomId);
        await this.server.socketSubscribe(`room/${response.data.roomId}`);
        this.setState({tournamentRoom: roomObj, gameToken: response.data.token, tournament: tournament})
        this.updateLeaderboard(this.state.gameState.tournaments.pending)
    }

    async setGameLink(gameAlias) {
        const game = this.state.gameState.hhs.games.find(game => game.alias.indexOf(gameAlias) >= 0)

        const gameFullName = game['absoluteName'];
        const token        = this.state.gameToken || this.state.uToken;
        const response     = await this.server.request('hhs/game/init', {game: gameFullName, token});
        this.setState({game: response.data.parameters.link})
    }

    addNewRoom(responce) {
        if (responce.state && responce.state.tournaments && responce.state.tournaments.rooms) {
            this.setState(state => {
                const gameState = {...state.gameState}
                responce.state.tournaments.rooms.forEach(newRoom => {
                    const index = gameState.tournaments.rooms.findIndex(room => room.id === newRoom.id)
                    if (gameState.tournaments.rooms[index]) {
                        gameState.tournaments.rooms[index] = {...gameState.tournaments.rooms[index], ...newRoom}
                    } else {
                        gameState.tournaments.rooms.push(newRoom)
                    }
                })

                return {gameState};
            })
        }
    }

    onMassageHandler(massage) {
        if (!massage.state) {
            return
        }
        const stateChanges = massage.state;

        if (stateChanges.tournaments) {
            this.updateLeaderboard(stateChanges.tournaments.pending);
        }

        if (stateChanges.user && stateChanges.user.values && !Array.isArray(stateChanges.user.values) && stateChanges.user.values.level) {
            this.setLevel(stateChanges.user.values.level);
        }
        if (stateChanges.user && stateChanges.user.values && !Array.isArray(stateChanges.user.values) && stateChanges.user.values.moneyPig) {
            this.setPig(stateChanges.user.values.moneyPig);
        }

        if (stateChanges.quest) {
            this.setQuest(stateChanges.quest);
        }

        if (stateChanges.monetization && stateChanges.monetization.offers) {
            this.setOffer(stateChanges.monetization.offers);
        }

        if (stateChanges.user) {
            this.setVip(stateChanges.user.vip);
        }

        if (stateChanges.tournaments && stateChanges.tournaments.rooms && this.state.tournamentRoom) {
            const room = stateChanges.tournaments.rooms.find(r => r.id === this.state.tournamentRoom.id);
            if (room && room.isClosed) {
                massageSender({message: "app.close"})
            }
            this.setState({tournamentRoom: room})
        }
    }

    updateLeaderboard(pending) {
        if (!pending) {
            return
        }
        this.updateUsersOnLeaderboard(pending);
        this.updateLeaderboardProfile();
        this.sortLeaderboard();
    }

    updateUsersOnLeaderboard(pending) {
        const roomId            = this.state.tournamentRoom ? this.state.tournamentRoom.id : null;
        const loadedLeaderboard = roomId ? pending[roomId] : null;
        if (!loadedLeaderboard) {
            return
        }

        this.setState(state => {
            const leaderboard = [...state.leaderboard];
            for (let i in loadedLeaderboard) {
                const player = loadedLeaderboard[i]
                const index  = leaderboard.findIndex(p => p.id === player[0]);
                if (index >= 0) {
                    leaderboard[index].score = player[1];
                } else {
                    leaderboard.push({
                        id:    player[0],
                        score: player[1]
                    });
                }
            }
            return {leaderboard}
        })
    }

    async updateLeaderboardProfile() {
        const noProfileUsers = this.state.leaderboard.filter(player => !player.name);
        if (noProfileUsers.length) {
            const noProfileUsersIds = noProfileUsers.map(player => player.id)
            const response          = await this.server.request('user/profile/batch', {userIds: noProfileUsersIds}, true, 'GET');
            const users             = response.data
            this.setState(state => {
                const leaderboard = [...state.leaderboard];
                for (let id of noProfileUsersIds) {
                    const name            = users[id] ? users[id].firstName : "User" + id.substr(0, 4);
                    const randomAvatarNum = Math.floor(Math.random() * 15) + 1;
                    const avatarUrl       = users[id] ? users[id].avatarUrl : `${this.state.staticUrl}icons/avatars/Avatars_${randomAvatarNum}.png`;

                    const index        = leaderboard.findIndex(e => e.id === id);
                    leaderboard[index] = {...leaderboard[index], name, avatarUrl};
                }
                return {leaderboard}
            })

        }

    }

    sortLeaderboard() {
        this.setState(state => {
            const leaderboard = [...state.leaderboard];
            leaderboard.sort((pPrev, pNext) => {
                return pPrev.score - pNext.score;
            })
            return leaderboard;
        })
    }

    setPig(pigData) {
        this.setState(state => {
            const pigObj = {...state.pig, ...pigData};
            const pig    = {curVal: pigObj.coins, maxVal: pigObj.maxCoins}
            return {pig}
        })
    }

    setLevel(levelData) {
        this.setState(state => {
            const userXPObj = {...state.level, ...levelData};
            const userXP    = {
                curVal: userXPObj.xp,
                maxVal: userXPObj.nextLevelXp
            }
            return {userXP}
        })
    }

    setQuest(questData) {
        if (!this.state.gameState) {
            return
        }

        this.setState(state => {
            const gameState = {...state.gameState};
            const oldQuest  = gameState.quest;
            questData.list.forEach(quest => {
                const index          = oldQuest.list.findIndex(q => q.id === quest.id)
                oldQuest.list[index] = {...oldQuest.list[index], ...quest}
            })
            const dailyQuestsObj = oldQuest.list.find(q => q.capture !== 'quest.master');
            const dailyQuests    = {
                curVal: dailyQuestsObj.progress,
                maxVal: dailyQuestsObj.goal
            }
            return {gameState, dailyQuests}
        })

    }

    setOffer(offers) {
        this.setState(state => {
            const offersArr = offers.sort((o1, o2) => {
                return o2.params.bonus - o1.params.bonus
            }).filter(o => {
                return o.expiresAt > Math.round(Date.now() / 1000)
            })

            return {offer: offersArr[0] || null}
        })
    }

    setVip(vip) {
        this.setState({vip: !!vip})
    }

    render() {
        return (
            <div className="App">
                {!this.state.tournamentId ?
                    <GameInterface
                        menuState={{
                            pig:         this.state.pig,
                            offer:       this.state.offer,
                            userXP:      this.state.userXP,
                            dailyQuests: this.state.dailyQuests,
                            vip:         this.state.vip
                        }}
                        menuSettings={
                            this.state.gameState ? this.state.gameState.menu.items.filter(e => e.menu === "game") : []
                        }

                        localization={
                            this.state.localization
                        }
                    />
                    :
                    <TournamentInterface leaderboard={this.state.leaderboard}
                                         tournamentRoom={this.state.tournamentRoom}
                                         tournament={this.state.tournament}
                                         makeFirstSpinText={localization.make_first_spin[this.state.localization]}
                                         localization={
                                             this.state.localization
                                         }
                                         uid={this.state && this.state.gameState && this.state.gameState.user.id || null}
                    />
                }

                {this.state.game ?
                    <Game game={this.state.game}/>
                    :
                    <div> {this.state.game} </div>
                }

            </div>
        )
    };


}

export default App;
