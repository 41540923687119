import {calculatePercent} from "../ImageElements/calculatePercent";

/**
 * Created by numbsquirell on 7/9/21
 */

function Bar(props) {
    const curVal                          = props.element && props.element.values && props.element.values.curVal;
    const maxVal                          = props.element && props.element.values && props.element.values.maxVal;
    const [percent, percentStr, slashStr] = calculatePercent({curVal, maxVal});

    return <div className={"bar_empty"}>
        <div className={"bar_full"} style={{
            width: percentStr
        }}>
        </div>
        <p className={"bar_text"}>
            {maxVal > 999 ? percentStr : slashStr}
        </p>
    </div>
}

export {Bar}