/**
 * Created by numbsquirell on 6/7/21
 */

const RECONNECT_INTERVAL     = 2000;
const RECONNECT_MAX_ATTEMPTS = 5;


export default class HTTPRequestController {

    static get RESPONSE_FORMAT() {
        return {
            TEXT: 'TEXT',
            JSON: 'JSON'
        };
    }

    static get METHODS() {
        return {
            POST: 'POST',
            GET:  'GET'
        };
    }

    static get ERRORS() {
        return {
            BAD_REQUEST:    {code: 400, message: 'Request error! Cannot serve request now.'},
            UNPROCESSABLE:  {code: 422, message: 'Unprocessable Entity!'},
            JSON_PARSE:     {code: 447, message: 'Response JSON parse error!'},
            CANT_RECONNECT: {code: 448, message: 'Cannot reconnect to server!'}
        };
    }

    static isOnline() {
        return (window.navigator.onLine === true);
    }

    constructor(baseUrl = '', format = null, method = null, mode = 'cors', cookies = false) {
        this._baseUrl = baseUrl;
        this._format = format || HTTPRequestController.RESPONSE_FORMAT.JSON;
        this._method = method || HTTPRequestController.METHODS.POST;
        this._mode = mode;
        this._cookies = cookies;

        this._url = null;
        this._params = null;
        this._permanentParams = null;

        this._isReconnecting = false;
        this._reconnectAttempt = 0;
        this._reconnectInterval = RECONNECT_INTERVAL;
        this._reconnectMaxAttempts = RECONNECT_MAX_ATTEMPTS;

        this._onErrorHandler = null;
        this._onConnectionLostHandler = null;
        this._onReconnectHandler = null;
    }

    async send(url, params = {}, reconnecting = false) {
        this._url = url;
        this._params = params;

        const request = {
            url: this._baseUrl + url,
            params: {
                headers: new Headers(),
                method: this._method,
                mode: this._mode
            }
        };

        const requestParams = {...this._permanentParams, ...params};

        if (this._format === HTTPRequestController.RESPONSE_FORMAT.JSON) {
            request.params.headers.append('Content-Type', 'application/json');
        }

        if (this._method === 'POST') {
            request.params.body = JSON.stringify(requestParams);
        }
        else if (this._method === 'GET') {
            request.url = `${request.url}?${this.stringifyQueryString(requestParams)}`;
        }

        const response = await global.fetch(request.url, request.params)
                                     .catch((error) => this._handleError({code: 0, message: error.message}));

        const data = await this._handleResponse(response);

        if (!reconnecting) {
            if (this._isReconnecting) {
                this._isReconnecting = false;
                this._reconnectAttempt = 0;
                if (!data.error && this._onReconnectHandler) {
                    this._onReconnectHandler();
                }
            }

            this._url = null;
            this._params = null;
        }

        return data;
    }

    async _handleResponse(response) {
        let responseData = {};

        if (!response || !response.ok) {
            return await this._handleError(response.error || {code: response.status, message: response.statusText});
        }

        if (this._format === HTTPRequestController.RESPONSE_FORMAT.JSON) {
            try {
                responseData = await response.json();
            }
            catch (error) {
                return await this._handleError(HTTPRequestController.ERRORS.JSON_PARSE);
            }
        }
        else if (this._format === HTTPRequestController.RESPONSE_FORMAT.TEXT) {
            responseData = await response.text();
        }
        else {
            throw new Error(`HTTPRequestController: send(): unknown response format: ${this._format}`);
        }

        return responseData;
    }

    async _handleError(error) {
        let tryReconnect = this._isReconnecting;
        if (!this._isReconnecting) {
            if (this._onErrorHandler) {
                tryReconnect = this._onErrorHandler(error);
            }
        }

        if (!tryReconnect) {
            return {error};
        }

        this._isReconnecting = true;
        if (this._reconnectAttempt === this._reconnectMaxAttempts) {
            if (this._onConnectionLostHandler) {
                this._onConnectionLostHandler(error);
            }
            return {error};
        }

        await setTimeout(()=>{}, RECONNECT_INTERVAL);
        this._reconnectAttempt++;

        return this.send(this._url, this._params, true);
    }

    set permanentParams(params) {
        this._permanentParams = params;
    }

    get permanentParams() {
        return this._permanentParams;
    }

    set onErrorHandler(handler) {
        this._onErrorHandler = handler;
    }

    set onConnectionLostHandler(handler) {
        this._onConnectionLostHandler = handler;
    }

    set onReconnectHandler(handler) {
        this._onReconnectHandler = handler;
    }

    stringifyQueryString(params) {
        const attributes = [];
        for (const key in params) {
            attributes.push(key + '=' + params[key]);
        }
        return attributes.join('&');
    }
}
